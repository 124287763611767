<template>
  <div>
    <div v-if="questionList.length" class="relative">
      <draggable
        v-model="questionList"
        tag="ul"
        ghost-class=""
        chosen-class=""
        drag-class=""
        class="flex items-center flex-col gap-3"
        :disabled="isDefault || questionList.length <= 1"
        @change="updateQuestionsOrder(questionList)"
        :animation="300"
        :handle="'.drag-handle'"
      >
        <li
          v-for="(question, qIndex) in questionList"
          :key="qIndex"
          class="item flex lg:flex-row md:flex-row flex-col gap-3 w-full lg:w-3/5 items-center m-auto relative"
        >
          <div
            class="drag-handle cursor-move m-auto lg:block md:block hidden"
            :class="
              (isDefault || questionList.length === 1) && 'cursor-not-allowed'
            "
          >
            <vs-icon size="24px" icon="drag_indicator" class=""></vs-icon>
          </div>
          <div
            class="rounded-lg p-4 space-y-3 w-full customFormsBorder grid relative"
          >
            <div
              class="headerBorder border-b absolute w-full p-5 flex items-center justify-between"
            >
              <p class="text-primary">Q{{ qIndex + 1 }}</p>
              <div
                class="text-sm text-right text-red-600 flex items-center gap-1 cursor-pointer"
                :class="{ 'pointer-events-none opacity-50': isDefault }"
                @click="openRemovePopup(qIndex)"
              >
                <p>Delete</p>
                <vs-icon icon="icon-trash-2" icon-pack="feather"></vs-icon>
              </div>
            </div>
            <div
              class="flex lg:flex-row flex-col items-start lg:items-center justify-start lg:gap-5 lg:pt-12 md:pt-12 pt-16"
            >
              <vs-select
                :disabled="isDefault"
                v-model="question.type"
                label="Question Type"
                autocomplete
                class="w-full"
                placeholder="Select Type"
                @change="handleTypeChange(null, qIndex)"
                :danger="question.type === null"
                :class="{ questionTypeClass: question.type === null }"
              >
                <vs-select-item
                  :key="itemIndex"
                  v-for="(item, itemIndex) in questionTypes"
                  :value="item.value"
                  :text="item.text"
                  :clearable="false"
                />
              </vs-select>
              <div class="flex items-center flex-row gap-3 mt-5 text-base">
                <p>Required</p>
                <vs-switch
                  class="customSwitch"
                  :disabled="isDefault"
                  v-model="question.is_mandatory"
                >
                </vs-switch>
              </div>
            </div>
            <vs-divider class="py-2" />
            <div class="flex flex-col">
              <vs-textarea
                ref="textarea"
                :success="question.question !== ''"
                :danger="shouldShowDanger(qIndex)"
                :disabled="isDefault"
                v-model="question.question"
                name="question"
                :data-vv-as="'Question' + (qIndex + 1)"
                data-vv-scope="basic"
                v-validate="'required'"
                class="mb-0 w-full transition duration-150"
                placeholder="Write your question"
                @input="touchedQuestions[qIndex] = true"
              />
              <div
                class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to form-question-message"
                :class="
                  question.question == '' &&
                  shouldShowDanger(qIndex) &&
                  'form-question-message-danger'
                "
              >
                <span class="span-text-validation">
                  Q{{ qIndex + 1 }} question is required
                </span>
              </div>
              <div v-if="question.type === 'iAgree'" class="pt-4 pb-1">
                <span class="text-sm"
                  ><b>Please note:</b> This question will only allow a checkbox
                  for 'Agree' as the response. Patients can either check the box
                  to agree or leave it unchecked if they do not agree.</span
                >
              </div>
            </div>
            <vs-divider v-if="question.type !== 'iAgree'" class="py-2" />

            <!-- Question Type area -->
            <!-- Yes or No -->
            <div
              v-if="question.type == 'yesOrNo'"
              class="flex items-center gap-3 lg:justify-start justify-between w-full"
            >
              <!-- Yes Button -->
              <vs-button
                disabled
                type="border"
                @click="selectOption(question, 'yes')"
                class="yesNoBorder w-full md:w-1/5 lg:w-1/6"
                textColor="#000"
              >
                Yes
              </vs-button>
              <!-- No Button -->
              <vs-button
                disabled
                type="border"
                @click="selectOption(question, 'no')"
                class="yesNoBorder w-full md:w-1/5 lg:w-1/6"
                text-color="#000"
              >
                No
              </vs-button>
              <!-- <vx-tooltip
                :ref="`mandatoryTooltip_${qIndex}`"
                class="flex"
                text="Selecting an answer will make that option mandatory"
                position="top"
                delay="0.2s"
              >
                <vs-icon
                  @click="toggleTooltip(`mandatoryTooltip_${qIndex}`)"
                  icon="info"
                  size="22px"
                ></vs-icon>
              </vx-tooltip> -->
              <!-- <vs-input class="w-full lg:w-auto" disabled placeholder="Yes" />
              <vs-input class="w-full lg:w-auto" disabled placeholder="No" /> -->
            </div>
            <!-- Multiple Choice -->
            <div v-if="question.type == 'multipleChoice'">
              <div
                v-for="(item, index) in question.options"
                :key="`options-${index}`"
                class="flex justify-start gap-2"
              >
                <vs-input
                  placeholder="Write your answer"
                  :label="'Answer ' + (index + 1)"
                  id="multipleChoiceGenerate"
                  class="w-full"
                  :name="`option_${index}`"
                  v-model="item.value"
                  v-validate="'required'"
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="
                    errors.first(`customForm.option_${index}`) ? true : false
                  "
                  :danger-text="
                    errors.first(`customForm.option_${index}`)
                      ? `Answer ${index + 1} is required`
                      : ''
                  "
                  :success="
                    !errors.first(`customForm.option_${index}`) &&
                    item.value != ''
                  "
                  data-vv-scope="customForm"
                />
                <vs-button
                  v-if="question.options.length !== 1"
                  color="danger"
                  type="border"
                  icon="icon-trash"
                  icon-pack="feather"
                  class="remove-item-btn"
                  @click="removeOption(qIndex, index)"
                ></vs-button>
              </div>
              <div
                @click="addField(qIndex)"
                class="text-base text-primary flex gap-1 items-center pt-4 cursor-pointer"
              >
                <vs-icon size="18px" icon="add"></vs-icon>
                <p class="underline">
                  {{
                    question.options.length
                      ? "Add another answer"
                      : "Add Answer"
                  }}
                </p>
              </div>
            </div>
            <!-- Free text -->
            <div v-if="question.type == 'freeText'">
              <vs-textarea
                disabled
                label="Answer"
                class="freeTextStyle m-auto w-full"
              />
            </div>
            <!-- Single Select -->
            <div v-if="question.type == 'singleSelect'">
              <div
                v-for="(item, index) in question.options"
                :key="`singleSelect-${index}`"
                class="flex justify-start gap-2"
              >
                <vs-input
                  placeholder="Write your answer"
                  :label="'Answer ' + (index + 1)"
                  class="w-full"
                  :name="`singleSelect_option_${index}`"
                  v-model="item.value"
                  v-validate="'required'"
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="
                    errors.first(`customForm.singleSelect_option_${index}`)
                      ? true
                      : false
                  "
                  :danger-text="
                    errors.first(`customForm.singleSelect_option_${index}`)
                      ? `Answer ${index + 1} is required`
                      : ''
                  "
                  :success="
                    !errors.first(`customForm.singleSelect_option_${index}`) &&
                    item.value != ''
                  "
                  data-vv-scope="customForm"
                />
                <vs-button
                  v-if="question.options.length !== 1"
                  color="danger"
                  type="border"
                  icon="icon-trash"
                  icon-pack="feather"
                  class="remove-item-btn"
                  @click="removeOption(qIndex, index)"
                ></vs-button>
              </div>
              <div
                @click="addField(qIndex)"
                class="text-base text-primary flex gap-1 items-center pt-4 cursor-pointer"
              >
                <vs-icon size="18px" icon="add"></vs-icon>
                <p class="underline">
                  {{
                    question.options.length
                      ? "Add another answer"
                      : "Add Answer"
                  }}
                </p>
              </div>
            </div>
            <div class="lg:hidden md:hidden">
              <vs-divider></vs-divider>
              <div class="flex items-center justify-around gap-2 text-primary">
                <div
                  @click="moveUp(qIndex)"
                  class="flex items-center gap-2 cursor-pointer"
                  :disabled="qIndex === 0"
                  :class="{
                    'text-black opacity-50 pointer-events-none':
                      qIndex === 0 || isDefault,
                  }"
                >
                  <vs-icon icon="arrow_upward"></vs-icon>
                  <p>Move Up</p>
                </div>
                <div
                  @click="moveDown(qIndex)"
                  class="flex items-center gap-2 cursor-pointer"
                  :disabled="qIndex === questionList.length - 1"
                  :class="{
                    'text-black opacity-50 pointer-events-none':
                      qIndex === questionList.length - 1 || isDefault,
                  }"
                >
                  <vs-icon icon="arrow_downward"></vs-icon>
                  <p>Move Down</p>
                </div>
              </div>
            </div>
          </div>
        </li>
        <vs-button
          v-if="!isDefault"
          type="border"
          class="flex items-center justify-center gap-3"
          @click="addQuestion"
          icon="add"
          >Add Question</vs-button
        >
      </draggable>
    </div>
    <div
      v-else
      class="flex flex-col lg:w-1/3 md:w-3/4 w-full m-auto py-32 items-center gap-8"
    >
      <vs-button
        :disabled="isDefault"
        class="w-3/4 text-lg font-semibold py-3"
        @click="addQuestion"
        >Add Question</vs-button
      >
      <p class="text-center font-semibold text-lg">
        Start adding questions to build <br />
        your consent form
      </p>
    </div>
    <vs-popup @update:active="cancelRemovePopup" :active.sync="removeBtnPopup">
      <div class="px-8 py-4 space-y-5">
        <p class="lg:text-left md:text-left text-center">
          Are you sure you want to delete this question?
        </p>
        <div
          class="flex gap-3 items-center lg:justify-end md:justify-end justify-center"
        >
          <vs-button class="lg:w-1/4 md:w-1/4 w-full" @click="removeQuestion"
            >Yes</vs-button
          >
          <vs-button
            class="lg:w-1/4 md:w-1/4 w-full py-2"
            type="border"
            color="danger"
            @click="cancelRemovePopup"
            >No</vs-button
          >
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  props: ["isDefault", "questions"],
  components: {
    draggable,
  },
  data() {
    return {
      questionList: [...this.questions],
      questionTypes: [
        { value: "yesOrNo", text: "Yes / No" },
        { value: "multipleChoice", text: "Multiple Choice" },
        { value: "freeText", text: "Free Text" },
        { value: "singleSelect", text: "Single Select" },
        { value: "iAgree", text: "I agree" },
      ],
      removeBtnPopup: false,
      selectedQuestionIndex: null,
      touchedQuestions: {},
      tooltipActive: false,
    };
  },
  methods: {
    toggleTooltip(ref) {
      if (this.tooltipActive) {
        this.$refs[ref][0].mouseleavex();
      } else {
        this.$refs[ref][0].mouseenterx();
      }
      this.tooltipActive = !this.tooltipActive;
    },
    selectOption(question, option) {
      // If the selected option is already clicked, unselect it
      if (question.mandatoryAnswer === option) {
        question.mandatoryAnswer = null;
      } else {
        question.mandatoryAnswer = option;
      }
    },
    resizeTextarea() {
      const textareaRefs = this.$refs.textarea;

      if (Array.isArray(textareaRefs)) {
        textareaRefs.forEach((textareaRef, index) => {
          if (textareaRef) {
            const textareaElement = textareaRef.$el.querySelector("textarea");
            if (textareaElement) {
              // Adjust the textarea height to fit the content
              textareaElement.style.height = "auto";
              textareaElement.style.height = `${textareaElement.scrollHeight}px`;
            } else {
              console.error(
                `Textarea element not found for question index ${index}`
              );
            }
          } else {
            console.error(`Textarea ref not found for question index ${index}`);
          }
        });
      } else {
        console.error("Textarea refs array not found");
      }
    },
    moveUp(index) {
      if (index > 0) {
        // Swap the current item with the one above it
        const temp = this.questionList[index];
        this.$set(this.questionList, index, this.questionList[index - 1]);
        this.$set(this.questionList, index - 1, temp);
        this.updateQuestionsOrder(this.questionList);
      }
    },
    moveDown(index) {
      if (index < this.questionList.length - 1) {
        // Swap the current item with the one below it
        const temp = this.questionList[index];
        this.$set(this.questionList, index, this.questionList[index + 1]);
        this.$set(this.questionList, index + 1, temp);
        this.updateQuestionsOrder(this.questionList);
      }
    },
    cancelRemovePopup() {
      this.removeBtnPopup = false;
      this.selectedQuestionIndex = null;
    },
    openRemovePopup(index) {
      // Store the index and open the popup
      this.selectedQuestionIndex = index;
      this.removeBtnPopup = true;
    },
    addField(index) {
      this.$set(
        this.questionList[index].options,
        this.questionList[index].options.length,
        { value: "" }
      );
      this.emitQuestionsUpdate();
    },
    removeOption(qIndex, index) {
      this.$delete(this.questionList[qIndex].options, index);
      this.emitQuestionsUpdate();
    },
    addQuestion() {
      const newQuestion = {
        question: "",
        type: null,
        options: [],
        is_mandatory: true,
        order: this.questionList.length + 1,
        mandatoryAnswer: null,
      };
      this.questionList.push(newQuestion);
      this.handleTypeChange(newQuestion);
      this.emitQuestionsUpdate();
    },
    removeQuestion() {
      if (this.selectedQuestionIndex !== null) {
        this.questionList.splice(this.selectedQuestionIndex, 1);
      }
      this.cancelRemovePopup();
      this.emitQuestionsUpdate();
    },
    updateQuestionsOrder(props) {
      if (props.length) {
        this.questionList = props.map((item, index) => {
          const data = {
            question: item.question,
            is_mandatory: item.is_mandatory,
            type: item.type ? item.type : null,
            order: index + 1,
            options: item.options,
            mandatoryAnswer: item.mandatoryAnswer,
          };
          if (item._id) {
            data["_id"] = item._id;
          }

          return data;
        });
      }
      this.emitQuestionsUpdate();
    },
    async emitQuestionsUpdate() {
      this.$emit("updateQuestion", [...this.questionList]);
    },
    addDefaultOptions(question) {
      if (["singleSelect", "multipleChoice"].includes(question.type)) {
        question.options = [{ value: "" }, { value: "" }, { value: "" }];
      }
    },
    handleTypeChange(question, index) {
      if (!question) {
        question = this.questionList[index];
      }

      if (
        ["singleSelect", "multipleChoice"].includes(question.type) &&
        !question.options.length
      ) {
        question.options = [{ value: "" }, { value: "" }, { value: "" }];
      } else if (!["singleSelect", "multipleChoice"].includes(question.type)) {
        question.options = [];
      }
    },
  },
  computed: {
    shouldShowDanger() {
      return (questionIndex) => {
        const question = this.questionList[questionIndex];
        return question.question === "" && this.touchedQuestions[questionIndex];
      };
    },
  },
  mounted() {
    // Call the resize method after the component is mounted
    this.$nextTick(() => {
      this.resizeTextarea();
    });
  },
};
</script>

<style scoped>
.customIconRotate {
  rotate: 90deg;
  background-color: white;
}
.yesNoBorder {
  border: 1px solid #626262;
}
.headerBorder {
  border-bottom: 1px solid #e0e0e0;
}
.freeTextStyle {
  width: 100% !important;
}
.customFormsBorder {
  border: 1px solid #e0e0e0;
}
.remove-item-btn {
  margin-top: 20px;
}
</style>

<style>
.questionTypeClass .input-select.vs-select--input {
  border-color: rgba(var(--vs-danger), 1);
}
.customFloat {
  position: absolute;
  top: -10px;
  margin: auto;
  z-index: 99999;
}
.vs-con-textarea textarea {
  transition: all 0.3s ease;
}
.vs-con-textarea textarea:focus {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.form-question-message {
  height: 0px;
  top: 3px;
}
.form-question-message-danger {
  height: 15px;
  top: 0px;
}
.vs-switch:focus-within {
  outline: 2px solid;
  outline-color: #0e4333;
  outline-offset: 2px;
}
/* .vs-con-textarea:has(.vs-textarea[success="true"]) {
  border: 1px solid rgba(var(--vs-success), 1) !important;
} */
.vs-con-textarea:has(.vs-textarea[danger="true"]) {
  border: 1px solid rgba(var(--vs-danger), 1) !important;
}
</style>
